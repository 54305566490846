import { Grid, MenuItem, Typography } from '@material-ui/core';
import DefaultContainer from 'layout/DefaultContainer';
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import AppController from 'base/App.controller';
import { IAdminBankRegistrations, REGISTRATION_TAB_OPTIONS } from './types';
import { Itranslation } from 'types';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import { cellConstructor } from 'components/common/wrappers/TableWrapper';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IRow } from 'components/common/types';
import MultiSelect from 'components/common/MultiSelect/MultiSelect';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import ScrollableTableWrapper from 'components/common/wrappers/ScrollableTableWrapper';
import MemberSearchDropdown from 'components/common/member-search/MemberSearchDropdown';

const AdminBankRegistrations: React.FC<IAdminBankRegistrations> = ({
    AppStore,
    AdminBankRegistrationsStore,
}) => {
    const {
        registrationsTab,
        MemberSearchDropdownStore,
        loading,
        countryCodeRenderCount,
        bankRegistrationGroupRenderCount,
        handleChangeBankRegGroupFilter,
        handleChangeCountryCodeFilter,
        countryCodeFilterOptions,
        bankRegistrationGroupFilterOptions,
        countryCodeFilterDefaultValue,
        bankRegistrationGroupFilterDefaultValue,
        handleClear,
        submitFilter,
        ScrollableTableWrapperStore,
        certificateFilterOptions,
        certificateFilter,
        handleChangeCertificateFilter,
        GenericErrorStore,
        loadMore,
        memberId,
        fetchMemberFilters,
        memberFound,
        resetState,
    } = AdminBankRegistrationsStore;
    const { t }: Itranslation = AppController.getTranslation(
        AppStore.host,
        'admin-bank-registrations',
    );

    useEffect(() => {
        if (memberId) {
            fetchMemberFilters();
        }
    }, [memberId]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, []);

    const renderActions = () => {
        return (
            <>
                <StyledIcon className={'icon edit'}>
                    <EditIcon />
                </StyledIcon>
                <StyledIcon className={'icon edit'}>
                    <DeleteIcon />
                </StyledIcon>
            </>
        );
    };

    const header = [t('country'), t('bankName'), t('action')];

    const cellTemplates = [
        (row: any) =>
            cellConstructor.text({
                row,
                keyId: 'registrationId',
                field: 'countries',
                value: row.countries.join(' | '),
            }),
        (row: any) =>
            cellConstructor.text({
                row,
                keyId: 'registrationId',
                field: 'bankRegistrationGroupName',
                className: 'table-bank-name',
            }),
        (row: IRow) =>
            cellConstructor.text({
                row,
                keyId: 'registrationId',
                field: 'actions',
                value: renderActions(),
            }),
    ];

    return (
        <>
            <div className="AdminBankRegistrations">
                <DefaultContainer>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <ButtonWrapper className="fetch-member-filters-btn">
                                {t('copyAllConfigturations')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: '40px' }}
                        container
                        direction="row"
                        alignItems="flex-end"
                        xs={12}
                        justify="space-between">
                        <Grid item container xs={8} alignItems="flex-end">
                            <Grid item xs={8}>
                                <MemberSearchDropdown
                                    MemberSearchDropdownStore={MemberSearchDropdownStore}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonWrapper className="add-new-config-btn">
                                {t('addNewConfiguration')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                    {memberFound && (
                        <Grid
                            style={{ marginTop: '40px', backgroundColor: '#FFF' }}
                            container
                            direction="column">
                            {/* HEADER */}
                            <Grid item container direction="row" justify="flex-start">
                                <Grid
                                    item
                                    // onClick={() =>
                                    //     handleRegistrationsTab(
                                    //         REGISTRATION_TAB_OPTIONS.BANK_REGISTRATIONS,
                                    //     )
                                    // }
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: '#514E5E',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            padding: '20px',
                                            borderBottom:
                                                // registrationsTab ===
                                                // REGISTRATION_TAB_OPTIONS.BANK_REGISTRATIONS ?
                                                '3px solid var(--primary)',
                                            // : 'none',
                                            cursor: 'pointer',
                                        }}>
                                        {t('bankRegistrationsHeader')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    // onClick={() =>
                                    //     handleRegistrationsTab(REGISTRATION_TAB_OPTIONS.AVAILABLE_BANKS)}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: '#929292',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            padding: '20px',
                                            cursor: 'pointer',
                                        }}>
                                        {t('availableBanksHeader')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {registrationsTab === REGISTRATION_TAB_OPTIONS.BANK_REGISTRATIONS && (
                                <>
                                    {/* FILTERS */}
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        style={{
                                            padding: '30px 0 20px 20px',
                                            backgroundColor: '#F9F9F9',
                                        }}>
                                        <Grid item container direction="row">
                                            {/* Country */}
                                            <Grid item xs={4} style={{ marginLeft: '20px' }}>
                                                <MultiSelect
                                                    key={countryCodeRenderCount}
                                                    options={countryCodeFilterOptions.map((c) => ({
                                                        title: c.name,
                                                        ...c,
                                                    }))}
                                                    textFieldProps={{
                                                        label: t('country'),
                                                        placeholder: t('searchCountry'),
                                                    }}
                                                    defaultValue={countryCodeFilterDefaultValue}
                                                    maxChips={3}
                                                    separator=", "
                                                    fullWidth
                                                    onChange={(countries) => {
                                                        handleChangeCountryCodeFilter(
                                                            countries.map(
                                                                (c) => c.code,
                                                            ) as string[],
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            {/* Bank group */}
                                            <Grid item xs={4} style={{ marginLeft: '20px' }}>
                                                <MultiSelect
                                                    key={bankRegistrationGroupRenderCount}
                                                    options={bankRegistrationGroupFilterOptions.map(
                                                        (group) => ({
                                                            title: group.bankRegistrationGroupName,
                                                            ...group,
                                                        }),
                                                    )}
                                                    textFieldProps={{
                                                        label: t('bankGroup'),
                                                        placeholder: t('searchBankGroup'),
                                                    }}
                                                    defaultValue={
                                                        bankRegistrationGroupFilterDefaultValue
                                                    }
                                                    maxChips={3}
                                                    separator=", "
                                                    fullWidth
                                                    onChange={(groups) => {
                                                        handleChangeBankRegGroupFilter(
                                                            groups.map(
                                                                (c) => c.bankRegistrationGroup,
                                                            ) as string[],
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            {/* Certificates */}
                                            <Grid
                                                item
                                                xs={4}
                                                style={{ margin: '30px 0px 0px 20px' }}>
                                                <SelectWrapper
                                                    value={certificateFilter}
                                                    onChange={(e) =>
                                                        handleChangeCertificateFilter(
                                                            e.target.value,
                                                        )
                                                    }
                                                    label={t('certificates')}>
                                                    {certificateFilterOptions.map((option) => (
                                                        <MenuItem
                                                            key={option.certificateId}
                                                            value={option.certificateId}>
                                                            {`${option.name} - ${option.expirationDate}`}
                                                        </MenuItem>
                                                    ))}
                                                </SelectWrapper>
                                            </Grid>
                                            {/* Clear */}
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="row-reverse"
                                            style={{ alignSelf: 'end' }}>
                                            <Grid item xs={1} style={{ margin: '0 10px' }}>
                                                <ButtonWrapper
                                                    onClick={submitFilter}
                                                    className="filter-btn filter-registrations-btn">
                                                    {t('filter')}
                                                </ButtonWrapper>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <ButtonWrapper
                                                    onClick={handleClear}
                                                    className="clear-btn clear-registrations-filter-btn">
                                                    {t('clear')}
                                                </ButtonWrapper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* TABLE */}
                                    <Grid item>
                                        <ScrollableTableWrapper
                                            disableRowSelect
                                            header={header}
                                            cellTemplates={cellTemplates}
                                            noDataMessage={t('noRegistrations')}
                                            ScrollableTableWrapperStore={
                                                ScrollableTableWrapperStore
                                            }
                                            loading={loading}
                                            callBackOnScroll={loadMore}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                </DefaultContainer>
            </div>
            <GenericErrorModal Store={GenericErrorStore} />
        </>
    );
};

export default inject('AppStore', 'AdminBankRegistrationsStore')(observer(AdminBankRegistrations));
