import { ICertificate } from '../types';
import AdminBankRegistrationsStore from './AdminBankRegistrationsStore';
import { AppStoreProps } from 'components/migrate-member/types';

export interface IAdminBankRegistrations extends AppStoreProps {
    AdminBankRegistrationsStore: AdminBankRegistrationsStore;
}

export interface IBankRegGroups {
    bankRegistrationGroup: string;
    bankRegistrationGroupName: string;
}

export interface IBankRegistrationFilterOptions {
    countryCodeFilter: string[];
    bankRegistrationGroupFilter: IBankRegGroups[];
    certificateFilter: ICertificate[];
}

export interface IBankRegistration {
    memberId: string;
    bankRegistrationGroup: string;
    bankRegistrationGroupName: string;
    bankIds: string[];
    countries: string[];
    registrationType: string;
    registrationStatus: string;
}

export const REGISTRATION_TAB_OPTIONS = {
    BANK_REGISTRATIONS: 'BANK_REGISTRATIONS',
    AVAILABLE_BANKS: 'AVAILABLE_BANKS',
} as const;
