import React, { useEffect, useState } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import type { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import type { CheckboxProps } from '@material-ui/core/Checkbox';
import type { TextFieldProps } from '@material-ui/core/TextField';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type TAutocomplte = Omit<Partial<AutocompleteProps<any, any, any, any>>, 'onChange'>;

export type MultiSelectOption = {
    title: string;
    [k: string]: unknown;
}[];

interface IMultiSelectProps extends TAutocomplte {
    options: MultiSelectOption;
    defaultValue?: MultiSelectOption;
    textFieldProps?: TextFieldProps;
    checkboxProps?: CheckboxProps;
    onChange: (value: IMultiSelectProps['options']) => void;
    maxChips?: number;
    separator?: string;
    [k: string]: any;
}

const useStyles = makeStyles(() => {
    return {
        inputRoot: {
            flexWrap: 'wrap',
            height: '100%',
            padding: '10px 55px 10px 10px !important',
        },
        chip: {
            borderRadius: '4px',
        },
    };
});

const MultiSelect: React.FC<IMultiSelectProps> = ({
    options,
    checkboxProps,
    textFieldProps,
    defaultValue = [],
    onChange,
    maxChips = options.length,
    separator,
    ...props
}) => {
    const classes = useStyles();
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <Autocomplete
            classes={{
                inputRoot: classes.inputRoot,
            }}
            multiple
            id="multi-select"
            options={options}
            disableCloseOnSelect
            value={value}
            onChange={(e, value) => {
                e.preventDefault();
                setValue(value);
                onChange(value);
            }}
            getOptionLabel={(option) => option.title}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...checkboxProps}
                    />
                    {option.title}
                </React.Fragment>
            )}
            renderInput={(params) => {
                return <TextField {...params} {...textFieldProps} />;
            }}
            renderTags={(value, getTagProps) => {
                if (separator) {
                    return (
                        <Chip
                            classes={{ root: classes.chip }}
                            style={{ backgroundColor: 'transparent' }}
                            label={value
                                .map((option) => option.title)
                                .slice(0, maxChips)
                                .join(separator)
                                .concat(value.length >= maxChips ? ' ...' : '')}
                        />
                    );
                }

                return (
                    <>
                        {value
                            .map((option, index) => (
                                <Chip
                                    key={index}
                                    classes={{ root: classes.chip }}
                                    label={option.title}
                                    {...getTagProps({ index })}
                                />
                            ))
                            .slice(0, maxChips)}
                    </>
                );
            }}
            {...props}
        />
    );
};

export default React.memo(MultiSelect, (prevProps, nextProps) => {
    const isEqual = JSON.stringify(prevProps) === JSON.stringify(nextProps);

    return isEqual;
});
