import { inject, observer, Provider } from 'mobx-react';
import React from 'react';

import DefaultContainer from 'layout/DefaultContainer';

import { IAdminBankRegistrations } from 'components/certificate-management/admin-bank-registrations/types';
import AdminBankRegistrations from './AdminBankRegistrations';

const TabAdminBankRegistrations: React.FC<IAdminBankRegistrations> = ({
    AppStore,
    AdminBankRegistrationsStore,
}) => {
    return (
        <div className="tabAdminBankRegistrations">
            <Provider AppStore={AppStore} AdminBankRegistrationsStore={AdminBankRegistrationsStore}>
                <DefaultContainer>
                    <AdminBankRegistrations
                        AdminBankRegistrationsStore={AdminBankRegistrationsStore}
                    />
                </DefaultContainer>
            </Provider>
        </div>
    );
};

export default inject(
    'AppStore',
    'AdminBankRegistrationsStore',
)(observer(TabAdminBankRegistrations));
