import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core';
import React from 'react';
import Spinner from '../spinner/Spinner';
import { inject, observer } from 'mobx-react';
//types
import { ICellConstructorTextProps, IRow, IScrollableTableWrapper } from '../types';

const styles = () => ({
    root: {
        '& .mainContainer': {
            marginTop: '40px',
            marginBottom: '30px',
            '&.emptyList': {
                color: 'var(--milled-wine)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'var(--fs-x-large)',
                minHeight: '344px',
                padding: '10px 10px',
            },
            '&.list': {
                '& .selectRow': {
                    cursor: 'pointer',
                },
            },
            '& .badge': {
                display: 'inline-block',
                lineHeight: '1em',
                padding: '7px 12px',
                backgroundColor: 'var(--badge-bg)',
                borderRadius: '14px',
                fontSize: 'var(--fs-small)',
                fontWeight: 'var(--font-weight-medium)',
                textTransform: 'capitalize',
                width: 'max-content',
                '&.error': {
                    backgroundColor: 'var(--bittersweet)',
                    color: 'white',
                },
                '&.processing': {
                    backgroundColor: 'var(--n26-wheat)',
                    color: 'white',
                },
                '&.initiated': {
                    backgroundColor: 'var(--active)',
                    color: 'white',
                },
                '&.sent': {
                    backgroundColor: 'var(--n26-wheat)',
                    color: 'white',
                },
                '&.invalid-status': {
                    backgroundColor: 'var(--bittersweet)',
                    color: 'white',
                },
                '&.success': {
                    backgroundColor: 'var(--dark-pastel-green)',
                    color: 'white',
                },
            },
            '& .actionIcon': {
                paddingRight: '40px',
                '& .MuiDivider-vertical': {
                    height: '34px',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                },
                '& .arrowIcon': {
                    position: 'relative',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingRight: '2px',
                    width: '22px',
                    height: '34px',
                },
            },
        },
    },
});

const ScrollableTableWrapper: React.FC<IScrollableTableWrapper> = ({
    ScrollableTableWrapperStore,
    header,
    cellTemplates,
    disableRowSelect,
    loading,
    noDataMessage,
    callback,
    endIcon,
    classes,
    callBackOnScroll,
    maxHeight,
}) => {
    const handleLoadMoreRow = async () => {
        callBackOnScroll ? await callBackOnScroll() : null;
    };

    const {
        data,
        selected,
        updateSelected,
        loadingNewRow,
        setLoadingNewRow,
        isLoaded,
    } = ScrollableTableWrapperStore;

    const handleSelectRow = async (row: IRow) => {
        if (!disableRowSelect && callback) {
            updateSelected(row);
            await callback(row);
        }
    };

    const onScroll: React.UIEventHandler<HTMLDivElement> = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
            if (isLoaded && !loadingNewRow) {
                setLoadingNewRow(true);
                await handleLoadMoreRow();
            }
        }
    };

    const handleBodyContent = () => {
        return (
            <React.Fragment>
                {data.map((row, index) => {
                    return (
                        <React.Fragment key={index}>
                            <TableRow
                                hover
                                key={index}
                                className={disableRowSelect ? 'row' : 'selectRow'}
                                onClick={() => handleSelectRow(row)}
                                selected={selected === row}>
                                {cellTemplates.map((template) => template(row))}
                                {endIcon && (
                                    <TableCell className={'actionIcon'} align={'right'}>
                                        <Divider orientation="vertical" />
                                        <span className={'arrowIcon'}>{endIcon}</span>
                                    </TableCell>
                                )}
                            </TableRow>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    };

    return (
        <div className={classes.root}>
            {loading && !loadingNewRow ? (
                <Paper className={'mainContainer emptyList'}>
                    <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />
                </Paper>
            ) : !data?.length ? (
                <Paper className={'mainContainer emptyList'}>
                    <div>{noDataMessage}</div>
                </Paper>
            ) : (
                <div>
                    <Paper className={'mainContainer list'}>
                        <TableContainer
                            style={{ margin: 'auto', maxHeight: maxHeight ?? '770px' }}
                            onScroll={onScroll}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow key={'head'}>
                                        {header.map((key) => (
                                            <TableCell key={key} align={'center'}>
                                                {key}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {handleBodyContent()}
                                    {loadingNewRow && isLoaded && (
                                        <TableRow>
                                            <TableCell
                                                align={'center'}
                                                colSpan={cellTemplates.length + 1}>
                                                <Spinner
                                                    type={Spinner.TYPE_SMALL}
                                                    color={Spinner.COLOR_SECONDARY}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            )}
        </div>
    );
};

export const cellConstructor = {
    text: function text({
        row,
        keyId,
        field,
        value,
        className,
        isBadge = false,
    }: ICellConstructorTextProps) {
        const defaultClass = isBadge ? 'badge' : 'default';
        let cellValue = value ? value : row[field] ? row[field] : '-';
        if (isBadge) {
            cellValue = cellValue.toLowerCase();
        }
        return (
            <TableCell
                key={`${row[keyId]}${field}`}
                align={'center'}
                className={!isBadge ? className : ''}>
                <span className={className ? className : defaultClass}>{cellValue}</span>
            </TableCell>
        );
    },
};

export default withStyles(styles, { name: 'ScrollableTableWrapper' })(
    inject('ScrollableTableWrapperStore')(observer(ScrollableTableWrapper)),
);
