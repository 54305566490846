import CertificateManagementStore from 'components/certificate-management/CertificateManagementStore';
import { AppStoreProps } from 'components/migrate-member/types';
import AdminBankRegistrationsStore from 'components/certificate-management/admin-bank-registrations/AdminBankRegistrationsStore';

export interface ICreateCSRProps extends AppStoreProps {
    CertificateManagementStore?: CertificateManagementStore;
}

export interface IAdminCSRProps extends AppStoreProps {
    CertificateManagementStore?: CertificateManagementStore;
    AdminBankRegistrationsStore?: AdminBankRegistrationsStore;
}

export interface ICertificateManagementTabs {
    certificates: React.ElementType;
    createCSR: React.ElementType;
    adminBankRegistrations: React.ElementType;
}

export interface ICompanyAdderss {
    companyName: string;
    companyDomain: string;
    country: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}

export interface ICompanyDetails {
    organizationUnit: string;
    ncaCountry: string;
    ncaLicenseNumber: string;
    companyNumber: string;
    subjectAlternativeNames: string;
}

type TKeyType = 'QSEAL' | 'QWAC' | 'OBSEAL' | 'OBWAC';

type TCertificateFormat = 'PEM';

export interface ICertificate {
    certificateId: string;
    keyId: string;
    certificate: string;
    format: TCertificateFormat;
    serial: string;
    keyType: TKeyType;
    expirationDate: string;
    name: string;
    status: string;
    customizationName: string;
}

interface ICertificateSigningRequest {
    keyId: string;
    csrPem: string;
    format: string;
    status: string;
    name: string;
    keyType: TKeyType;
}

interface IPageInfo {
    page: number;
    size: number;
    total: number;
    totalPage: number;
}

export interface IGetCertificatesResponse {
    certificates: ICertificate[];
    pageInfo: IPageInfo;
}

export interface IGetCSRsResponse {
    csr: ICertificateSigningRequest[];
    pageInfo: IPageInfo;
}

export const UK = 'UK';
export const EU = 'EU';
export type TRegion = typeof UK | typeof EU;
export const CREATE = 'CREATE';
export const CERTIFICATE_SCREEN = {
    CERTIFICATE: 'CERTIFICATE',
} as const;
